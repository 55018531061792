import { createWebHistory, createRouter } from 'vue-router';
import pathLoader from '../plugins/loader'
import store from '../store'

const routes = [
    {
        path: '/',
        name: 'homePrivate',
        component: pathLoader(null, 'homePrivate'),
        redirect: 'commandes',
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: pathLoader('dashboard'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/commandes',
                name: 'commandes',
                component: pathLoader('commandes'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/detailsCommande',
                name: 'detailsCommande',
                component: pathLoader('detailsCommande'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/article',
                name: 'article',
                component: pathLoader('article'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/montage',
                name: 'montage',
                component: pathLoader('montage'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/detailsMontage',
                name: 'detailsMontage',
                component: pathLoader('detailsMontage'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/reception',
                name: 'reception',
                component: pathLoader('reception'),
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: '/',
        name: 'homeDevis',
        component: pathLoader(null, 'homeDevis'),
        redirect: 'nouveauDevis',
        children: [
            {
                path: '/panier',
                name: 'panier',
                component: pathLoader('panier'),
                meta: {
                    requiresAuth: true
                }
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: pathLoader('login'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.token) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router;
