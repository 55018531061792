import { createApp } from 'vue'
import App from './App.vue'
import rooter from '../src/router/index'
import install from './install'
import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'
import './registerServiceWorker'
import 'material-icons/iconfont/material-icons.css'
import i18n from './i18n'
import vuex from "./store"
import easySpinner from 'vue-easy-spinner'
import Switches from 'vue-switches'
import excel from 'vue-excel-export'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vClickOutside from "vue2-click-outside";

createApp(App)
    .use(i18n)
    .use(vuex)
    .use(excel)
    .use(easySpinner, {
        /*options*/
        prefix: 'easy',
    })
    .use(Switches)
    .use(vClickOutside)
    .use(VueSvgIconPlugin, { tagName: 'icon' })
    .component('VueDatePicker', VueDatePicker)
    .use(install)
    .use(rooter)
    .mount('#app')
